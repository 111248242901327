/* input[type=file]::file-selector-button {
    border: 2px solid #6c5ce7;
    padding: .2em .4em;
    border-radius: .2em;
    background-color: #a29bfe;
    transition: 1s;
  }
  
  input[type=file]::file-selector-button:hover {
    background-color: #81ecec;
    border: 2px solid #00cec9;
  } */

  /* .css-tt355c-MuiTypography-root{
    font-weight: 400;
    color: #001328;
  }
  
    .Otp-Img{
      background-color:#001328 ;
      height:100vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .All-O{
      margin:25px 0px 0px 30px;
    }
    
    .ArrowIocon{
       color:#A4A4A4 ;
    }
    
    .Ox-t{
      font-family: 'Poppins', sans-serif!important;
      margin: 0px;
      color: #001328;
    }
    
    .po-text{
      color:#223943;
    }
    
    .Otp-S{
      margin-top:200px;
    }
    
    
    .Otp-box{
      margin-top: 51px;
    }
    
    
    .enter-O{
      color: #001328;
      font-family: 'Poppins', sans-serif !important; 
      font-size: 18px !important;
      margin-bottom: 6px;
    }
    
    .text{
      color: #798593;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
    
    .otp-btn{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top:12%;
    
    }
    
    .otp-verify{
      background: linear-gradient(94.45deg, #0EBBF4 30.83%, #044894 74.18%);
      border: none;
      font-family: 'Poppins', sans-serif !important;
      width: 285px;
      height:56px;
      font-size:17px!important;
      border-radius:10px !important;
    }
    
    .send-otp{
      font-family: 'Poppins', sans-serif !important;
      color: #9EA0A5;
      margin-top: 1%;
    }
    .sp-resend{
      text-decoration: none;
    } */
    
    .css-2imjyh{
      margin-bottom:30px;
      justify-content: center;
    }

    body{
      font-family: 'Poppins', sans-serif !important
    }